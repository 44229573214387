import Vue from 'vue'
import http from './http'
import App from './App.vue'
import Vant from 'vant';
import { Toast, Notify, Dialog } from 'vant';
import qs from 'qs';



import 'vant/lib/index.css';
import router from './router'
import store from './store'


Vue.use(Vant);
Vue.prototype.$qs = qs;
Vue.prototype.$Toast = Toast
Vue.prototype.$Notify = Notify
Vue.prototype.$Dialog = Dialog
Vue.prototype.$http = http
Vue.config.productionTip = false

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
    if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
        let isLogin = store.state.login.userInfo || {};
        if (Object.keys(isLogin).length === 0) { // 判断当前是否登录
            Toast('暂未登陆')
            next({
                path: '/'
            })
        }
        else { //否则就跳转到下面的登录界面

            next(); //如果登录了next()就表示允许跳转到要去的路由
        }
    } else {  //如果该路由不需要登录验证就直接允许跳转
        let isLogin = store.state.login.userInfo || {};
        if (Object.keys(isLogin).length > 0) {
            next({
                path: '/home'
            })
        }
        next();
    }

});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
