import Vue from 'vue'
import Vuex from 'vuex'
import Http from '../http'
Vue.use(Vuex);

const moduleA = {
    state: {
        count: localStorage.getItem("count") || 0,
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},//先去localStorage中获取数据
    },
    mutations: {
        setCount (state) {
            Http.get('cart/get_cart_num', {
                params: {
                    user_id: state.userInfo.user_id
                }
            }).then(res => {
                localStorage.setItem('count', res.data.data);
                state.count = res.data.data;
            })
        },
        setuserInfo (state, v) {
            localStorage.setItem('userInfo', JSON.stringify(v));//将传递的数据先保存到localStorage中
            state.userInfo = v;// 之后才是修改state中的状态
        },

        clearSetuserInfo (state) {
            localStorage.removeItem('userInfo');
            state.userInfo = {};
            localStorage.removeItem('count');
            state.count = 0;
        }
    },
    actions: {
        CountAsync ({ commit }) {
            return new Promise((resolve) => {
                commit('setCount')
                resolve()
            })
        },
        loginAsync ({ commit }, userInfo) {
            return new Promise((resolve) => {
                console.log('测试载荷---' + userInfo);
                commit('setuserInfo', userInfo)
                resolve()
            })
        },
        genxUserInfoAsync ({ commit, state }) {
            console.log(state)
            return new Promise((resolve, reject) => {
                Http.get('user/get_user_info', {
                    params: {
                        user_id: state.userInfo.user_id
                    }
                }).then(res => {
                    let user = Object.assign(state.userInfo, res.data.data)

                    commit('setuserInfo', user)
                    resolve(user)
                }).catch(error => {
                    reject(error)
                })


            })
        },
        clearLoginAsync ({ commit }) {
            return new Promise((resolve) => {
                commit('clearSetuserInfo')
                resolve()
            })
        }
    },
    getters: {

    }
}

const store = new Vuex.Store({
    modules: {
        login: moduleA
    }
})
export default store